<template lang="pug">
.w-full.min-h-screen.flex.px-5
  transition(
    appear,
    name="enterAnimation",
    enter-from-class="opacity-0 transform translate-x-10",
    enter-active-class="transition transition-all duration-500 ease-in-out opacity-1 transform translate-x-0"
  )
    .box-signup.mx-auto.my-auto.w-full.rounded-xl.shadow-2xl.bg-white.overflow-hidden
      .grid.grid-cols-2
        .login.p-10.flex.flex-col
          h1.text-4xl.font-bold.mb-5.text-gray-700 {{ $t('login.login') }}
          input.py-3.px-5.rounded-md.mb-5.border.border-gray-100(
            type="text",
            name="email",
            placeholder="E-mail",
            v-model="formulario.email",
            :class="['focus:outline-none', { 'ring-2 ring-inset ring-red-500': error_email }]"
          )
          input.py-3.px-5.rounded-md.border.mb-5.border-gray-100(
            type="password",
            name="password",
            placeholder="Password",
            v-model="formulario.password",
            :class="['focus:outline-none', { 'ring-2 ring-inset ring-red-500': error_psw }]"
          )
          .flex.justify-end.mb-5 
            p.cursor-pointer {{ $t('login.lost_psw') }}
          .p-3.text-center.bg-blue-500.text-white.font-bold.rounded.cursor-pointer(
            @click="login",
            :class="{ 'opacity-50': isLoggin }"
          ) {{ $t('login.button') }}
          p.text-red-500.text-xs.text-center.mt-3.-mb-5 {{ error }}
        .w-full.h-full.bg.flex
          .backgrop
          .z-10.text-white.relative.text-center.p-10.text-lg.my-auto
            h1.font-bold.text-4xl.mb-5 {{ $t('login.bienvenido') }}
            p.mb-3 {{ $t('login.msg1') }}
            p {{ $t('login.msg2') }}
</template>
<script>
export default {
  name: "login",
  data() {
    return {
      formulario: {
        email: null,
        password: null,
      },
      error_email: false,
      error_psw: false,
      reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    };
  },
  computed: {
    isLoggin() {
      return this.$store.getters["auth/isLoading"];
    },
    error() {
      let msg = "";
      switch (this.$store.getters["auth/getError"]) {
        case "password":
          msg = this.$t("login.error_password");
          break;
        case "email":
          msg = this.$t("login.error_email");
          break;
        case "any":
          msg = this.$t("login.error_any");
          break;
      }
      return msg;
    },
  },
  methods: {
    async login() {
      if (!this.isLoggin) {
        this.error_psw = false;
        this.error_email = false;
        const { email, password } = this.formulario;
        if (password == null || password == "") this.error_psw = true;
        if (email == null || email == "" || !this.reg.test(email)) {
          this.error_email = true;
        }
        if (!this.error_psw && !this.error_email) {
          await this.$store.dispatch("auth/login", { email, password });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.box-signup {
  max-width: 1200px;
}

.bg {
  background-image: url("~@/assets/login_bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background-image: linear-gradient(
      45deg,
      rgb(100, 157, 218) 0%,
      rgb(0, 0, 0) 50%,
      rgb(100, 157, 218) 100%
    );
    opacity: 0.7;
    top: 0;
    z-index: 0;
  }
  .backgrop {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    backdrop-filter: blur(3px);
    z-index: 0;
  }
}
</style>
